import React, { useEffect, useState } from 'react';
import './Header.css';
import Lottie from 'react-lottie';
import animationData from '../../lotties/cyber2.json';
import { globalStyles, globalTexts } from '../../styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTelegram  } from '@fortawesome/free-brands-svg-icons';

const Header = () => {

    const [width, setWidth] = useState(window.innerWidth)

    useEffect(() => {
        const handleResize = () => setWidth(window.innerWidth);
        window.addEventListener("resize", handleResize)

        return () => {
            window.removeEventListener("resize", handleResize)
        }
    }, [width])

    const lottieStyle = {
        width: 600,
        height: 600,
        '@media screen and (max-width: 768px)': {
            height: '100px !important',
            width: '400px !important'
        }
    }

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice"
        }
      };

      const changeToQuizLocation = () => {
          window.location.replace("https://t.me/+49SE3U0u0R8zY2Fk")
      }

  return (
    <div className='header-div' style={{ 
            fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif" 
        }}>
        <div className='text-header-div'>
            <h1>{ globalTexts.MainTitleInTheHeader }</h1>
            <p style={{ color: '#fff' }}>{globalTexts.SecondryTitleInTheHeader}</p>
            <button className='start-the-test-button' 
                    style={{ backgroundColor: globalStyles.ButtonsHeaderColor }} 
                    onClick={changeToQuizLocation}> Join us! 
                    <FontAwesomeIcon 
                        icon={faTelegram} 
                        style={{ width: '20px', color: '#29a8ea' }} 
                    />
            </button>
                    
        </div>

        <div style={{ width: '80vw', height: '20%' }}>
            <Lottie 
	            options={defaultOptions}
                className="lottie-icon-1"
                width={width < 768 ? '110%' : '100%'}
                height={width < 768 ? '110%' : '100%'}
            />
        </div>

    </div>
  )
}

export default Header;