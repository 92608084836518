import React from 'react';
import StepsBefore from '../../components/StepsBefore/StepsBefore';

const Quiz = () => {
  return (
    <div>
        <StepsBefore />
    </div>
  ) 
}

export default Quiz;