import React, { useState, useEffect } from 'react';
import axios from 'axios'; // if axios is used
import { borderRadius, display, height } from '@mui/system';
import { red } from '@mui/material/colors';
import './TelegramResultsComponents.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMedal } from '@fortawesome/free-solid-svg-icons';
import logoSvgImg from '../../assets/logo.svg';
import crown from '../../assets/crown.svg';

const example = [
    { correct_count: 19, username: "Itay Itay" },
    { correct_count: 20, username: "Yoav Yoav" },
    { correct_count: 21, username: "Avi Avi" },
    { correct_count: 22, username: "Meni Ozeri" },
    { correct_count: 30, username: "Itay Gonen" },
    { correct_count: 24, username: "Ido Naor" },
    { correct_count: 3, username: "Ginatasdkadfksldgfsfmgkla;dflskndgfmkld;';lkfnsmd,l.; maskdlflsadmnfmkladfmnm,l" },
    { correct_count: 24, username: "Gal" },
    { correct_count: 24, username: "Gal2" },
    { correct_count: 24, username: "Gal4" },
    { correct_count: 24, username: "Gal5" },
    { correct_count: 24, username: "Gal6dksfjkm,ladgksmnams,lf;dslgnfamflds;dlkjnbdmksl" },
    { correct_count: 18, username: "Gal7" },
];

const example2 = [
    { correct_count: 19, username: "Itay Itay" },
    { correct_count: 20, username: "Yoav Yoav" },
    { correct_count: 21, username: "Avi Avi" },
];

const BASE_URL = "https://cyberask.online/api"

const TelegramResultsComponents = () => {
    const [currentTopic, setCurrentTopic] = useState(null);
    const [currentTime, setCurrentTime] = useState(null);
    const [currentData, setCurrentData] = useState(example);
    const [topThreeUsers, setTopThreeUsers] = useState(example2);
    const [loading, setLoading] = useState(true)


    const fetchData = async (topic, time) => {
        try {
            setLoading(true)
            const response = await axios.get(`${BASE_URL}/get-results/${topic.toLowerCase()}`);
            setLoading(false)
            console.log("URL", `${BASE_URL}/get-results/${topic.toLowerCase()}`)
            console.log("A", response.data)
            const dataForTime = response.data[time];
            console.log("response.data[time]", dataForTime)
            setCurrentData(dataForTime);
            const sortedData = dataForTime.sort((a, b) => b.correct_count - a.correct_count);
            setTopThreeUsers(sortedData.slice(0, 3));
        } catch (error) {
            console.error('Error fetching data:', error);
            setCurrentData([]);
            setTopThreeUsers([]);
        }
    };

    useEffect(() => {

      const getAllTheBests = async () => {
        console.log("Start...")
        setLoading(true)
        const response = await axios.get(`${BASE_URL}/get-results/all-time`);
        console.log(response.data)
        console.log("Finish")
        setLoading(false)
        const dataForTime = response.data;
        setCurrentData(dataForTime);
        const sortedData = dataForTime.sort((a, b) => b.correct_count - a.correct_count);
        setTopThreeUsers(sortedData.slice(0, 3));
      }
    
      getAllTheBests()

    }, [])


    const handleTabClick = (topic) => {
        setCurrentTopic(topic);
        setCurrentTime('lastWeek');
        fetchData(topic, 'lastWeek');
        console.log("Topic changed to ", topic)
    };

    const handleTimeButtonClick = (time) => {
        setCurrentTime(time);
        console.log("Clicked! and changed to ", time)
        if (currentTopic) {
            fetchData(currentTopic, time);
        }
    };

    return (
        <>
            { loading ? <div className='loading-telegram-results-spinner-div'> 
              <img src={logoSvgImg} className='loading-telegram-results-spinner-div-animation' style={{ width: '150px', height:'150px' }} />
            </div> :
            <>  
            <div className='container-telegram-results'>
                <h2 className='title-telegram-results'>Cyberask's Leaderboard</h2>
                <div className='tabs-telegram-results'>
                    <span className={currentTopic === 'Programming' ? 'clickedtab' : 'tab'} onClick={() => handleTabClick('Programming')}>Programming</span>
                    <span className={currentTopic === 'Practical' ? 'clickedtab' : 'tab'} onClick={() => handleTabClick('Practical')}>Practical</span>
                    <span className={currentTopic === 'OS' ? 'clickedtab' : 'tab'} onClick={() => handleTabClick('OS')}>OS</span>
                    <span className={currentTopic === 'Networking' ? 'clickedtab' : 'tab'} onClick={() => handleTabClick('Networking')}>Networking</span>
                </div>
                {currentTopic && (
                    <div className='times-telegram-results'>
                        <span className={currentTime === 'lastWeek' ? 'clickedtab' : 'tab'} onClick={() => handleTimeButtonClick('lastWeek')} id="time-telegram-results">Weekly</span>
                        <span className={currentTime === 'lastMonth' ? 'clickedtab' : 'tab'} onClick={() => handleTimeButtonClick('lastMonth')} id="time-telegram-results">Monthly</span>
                        <span className={currentTime === 'allTime' ? 'clickedtab' : 'tab'} onClick={() => handleTimeButtonClick('allTime')} id="time-telegram-results">All Time</span>
                    </div>
                )}
                <div className='top-three'>
                    <div className="left-user">
                        {topThreeUsers.length >= 2 && (
                            <div className="inner-left-user">
                                <FontAwesomeIcon icon={faMedal} style={{ color: 'silver' }} />
                                <p className='username-telegram-results'>{topThreeUsers[1].username.slice(0, 16)}</p>
                                <p className='silver-score'>{topThreeUsers[1].correct_count}</p>
                            </div>
                        )}
                    </div>
                    <div className="middle-user">
                        {topThreeUsers.length >= 1 && (
                            <div className="inner-middle-user">
                                <img src={crown} />
                                {/* <FontAwesomeIcon icon={faMedal} style={{ color: 'gold', fontSize: '1.5rem' }} /> */}
                                <p className='username-telegram-results'>{topThreeUsers[0].username.slice(0, 16)}</p>
                                <p className='gold-score'>{topThreeUsers[0].correct_count}</p>
                            </div>
                        )}
                    </div>
                    <div className="right-user">
                        {topThreeUsers.length >= 3 && (
                            <div className='inner-right-user'>
                                <FontAwesomeIcon icon={faMedal} style={{ color: '#CD7F32' }} />
                                <p className='username-telegram-results'>{topThreeUsers[2].username.slice(0, 16)}</p>
                                <p className='bronze-score'>{topThreeUsers[2].correct_count}</p>
                            </div>
                        )}
                    </div>
                </div>

                <div className='remaining'>
                    {/* Display remaining users based on currentData */}
                    {currentData && currentData.slice(3,10).map((user, index) => (
                      <>
                        <div key={index} className='remaining-item'>
                            {/* <div className='userRank'>{index + 4}</div> */}
                            <div className='userName'>{user.username.slice(0, 16)}</div>
                            <div className='userScore'>{user.correct_count}</div>
                        </div>
                        {index === 6 ? null : 
                        <div className='div-hr-style-results'>
                          <hr className='hr-style-results' />
                        </div>}
                      </>
                    ))}
                </div>
            </div>
            </> }
        </>
    );
};

const styles = {

    topThree: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-end',
        marginBottom: '20px',
        gap: '20px'
    },
    topUserContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    topUser: {
        textAlign: 'center',
        padding: '10px',
        backgroundColor: '#2c2c2e',
        borderRadius: '10px'
    },
    topUserFirst: {
        marginTop: '30px',
        backgroundColor: '#2c2c2e'
    },
    topUserBadge: {
        fontSize: '20px',
        marginBottom: '5px'
    },
    topUserName: {
        fontSize: '16px',
        fontWeight: 'bold'
    },
    topUserScore: {
        fontSize: '14px'
    },
    remaining: {
        borderTop: '1px solid #2c2c2e',
        paddingTop: '10px'
    },
    user: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '10px 0'
    },
    userRank: {
        fontSize: '16px',
        fontWeight: 'bold'
    },
    userName: {
        fontSize: '16px'
    },
    userScore: {
        fontSize: '14px'
    }
};

export default TelegramResultsComponents;
