import React, { Fragment } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faClose, faLeftLong, faChevronLeft, faXmark } from '@fortawesome/free-solid-svg-icons'
import Navbar from '../../components/Navbar/Navbar';
import Footer from '../../components/Footer/Footer';
import TelegramResultsComponents from '../../components/TelegramResultsComponents/TelegramResultsComponents'

const TelegramResults = () => {
  
  const returnToHomePage = () => {
    window.location.replace("/")
  }

  return (
  <>
  <div className='outer-icon'>
        <FontAwesomeIcon 
          icon={faChevronLeft}
          className='left-icon-new'
          onClick={returnToHomePage}
        />
      </div>
   <div className='telegram-results' style={{ padding: '30px 0px' }}>
      
        <TelegramResultsComponents />
   </div>
   </>
  )
}

export default TelegramResults;